/* eslint-disable import/no-extraneous-dependencies */
import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';

import './HubShell.js';

// MSAL imports
import {
  PublicClientApplication,
  EventType,
  AuthenticationResult,
} from '@azure/msal-browser';
import {
  AzureConfiguration,
  azureConfigurations,
  AzureScope,
} from './constants/AzureConfigurations.js';

import './components/HubLoginForm.js';

import '@mch/nn-web-viz/dist/nn-spinner.js';

@customElement('auth-shell')
export class AuthShell extends LitElement {
  @property({ type: String }) _authToken = '';

  @property({ type: Boolean }) _loggingIn = false;

  @property({ type: Object }) _authConfig: AzureConfiguration =
    azureConfigurations.config['90north'];

  @property({ type: Object }) _authScope: AzureScope =
    azureConfigurations.scope['90north'];

  constructor() {
    super();

    this._catchRedirectResponse();
  }

  async _acquireTokenSilently(instance: PublicClientApplication | null = null) {
    this._loggingIn = true;
    const shouldStop = instance == null;
    const msalInstance =
      instance == null ? await this._getMsAdClient() : instance;

    try {
      const result = await msalInstance.acquireTokenSilent(this._authScope);

      this._setActiveAccount(msalInstance);
      this._saveLoginInformation(result);

      return result;
    } catch (error) {
      if (shouldStop) {
        this._loggingIn = false;
      }
      throw new Error('Error acquiring token silently');
    }
  }

  async _catchRedirectResponse(
    instance: PublicClientApplication | null = null,
  ) {
    this._loggingIn = true;
    const msalInstance =
      instance == null ? await this._getMsAdClient() : instance;
    const activeAccount = msalInstance.getActiveAccount();

    if (activeAccount) {
      this._acquireTokenSilently();
    } else {
      msalInstance
        .handleRedirectPromise()
        .then((result: AuthenticationResult | null) => {
          if (result === null) {
            this._acquireTokenSilently();
          } else {
            this._setActiveAccount(msalInstance);
            this._saveLoginInformation(result);
          }
        });
    }
  }

  _login({ detail: { email } }: { detail: { email: string } }): void {
    const domain = email.split('@')[1]?.toLowerCase();
    const hasDsiCom = domain === 'dsi.com' || domain === 'daiichisankyo.com';

    if (hasDsiCom) {
      this._authConfig = azureConfigurations.config.dsi;
      this._authScope = azureConfigurations.scope.dsi;
    }

    this._loginWithMsAd();
  }

  // eslint-disable-next-line class-methods-use-this
  async _getMsAdClient() {
    const msalInstance = new PublicClientApplication(this._authConfig);

    await msalInstance.initialize();

    this._setActiveAccount(msalInstance);

    msalInstance.enableAccountStorageEvents();
    msalInstance.addEventCallback((event: any) => {
      if (!event) return;

      if (
        event.eventType === EventType.LOGIN_SUCCESS ||
        event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
        event.eventType === EventType.SSO_SILENT_SUCCESS
      ) {
        const account = (event.payload as AuthenticationResult)?.account; // Cast to AuthenticationResult
        if (account) {
          msalInstance.setActiveAccount(account);
        }
      }
    });

    return msalInstance;
  }

  // eslint-disable-next-line class-methods-use-this
  _setActiveAccount(msalInstance: PublicClientApplication) {
    // Default to using the first account if no account is active on page load
    if (
      !msalInstance.getActiveAccount() &&
      msalInstance.getAllAccounts().length > 0
    ) {
      // Account selection logic is app dependent. Adjust as needed for different use cases.
      msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
    }
  }

  async _loginWithMsAd() {
    this._loggingIn = true;

    const instance = await this._getMsAdClient();

    try {
      const result = await this._acquireTokenSilently(instance);
      this._saveLoginInformation(result);
    } catch (error) {
      this._catchRedirectResponse(instance);

      instance.loginRedirect(this._authScope);
    } finally {
      this._loggingIn = false;
    }
  }

  async _saveLoginInformation(result: { accessToken: string }) {
    if (!result) return;

    this._authToken = result?.accessToken;
    this._loggingIn = false;
  }

  render() {
    if (this._loggingIn) {
      return html` <nn-spinner theme="fancy"></nn-spinner>`;
    }

    if (this._authToken === '') {
      return html` <div>
        <hub-login-form @login=${this._login}></hub-login-form>
      </div>`;
    }

    return html`<hub-shell></hub-shell>`;
  }
}
